<template lang="pug">
    div.col-lg-6.col-md-6.layout-spacing
        div.statbox.widget.box.box-shadow
            div.widget-header
                div.row
                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                        h4
                            svg.feather.feather-cpu(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                rect(x="4" y="4" width="16" height="16" rx="2" ry="2")
                                rect(x="9" y="9" width="6" height="6")
                                line(x1="9" y1="1" x2="9" y2="4")
                                line(x1="15" y1="1" x2="15" y2="4")
                                line(x1="9" y1="20" x2="9" y2="23")
                                line(x1="15" y1="20" x2="15" y2="23")
                                line(x1="20" y1="9" x2="23" y2="9")
                                line(x1="20" y1="14" x2="23" y2="14")
                                line(x1="1" y1="9" x2="4" y2="9")
                                line(x1="1" y1="14" x2="4" y2="14")
                            template {{ $t('comp.admcenter')}}
                div.widget-content.widget-content-area
                    div.mx-auto.text-center
                        select.selectpicker.col-12.mx-auto(@change="getCenters" v-model="idSelectedCenter")
                            option(:value=" MyCenter.idCenter" v-for="MyCenter in MyCenters") {{ MyCenter.sCenterName }}

                    div.table-responsive.mt-5
                        table.table.table-bordered.table-hover.mb-4
                            thead
                                tr
                                    th {{ $t('comp.admcenter1')}}
                                    th {{ $t('comp.admcenter2')}}
                                    th {{ $t('comp.admcenter3')}}
                            
                            tbody
                                tr(v-for="center in centerList")
                                    td {{ center.sCenterType }}
                                    td {{ center.sCenterName }}
                                    td {{ center.sCity }}

</template>
<script>
/* eslint-disable */
export default {
    name: 'DependingCentersComponent',
    data() {
        return {
            centerList: [],
            MyCenters: [],
            idSelectedCenter: 0
        }
    },
    methods: {
        async getCenters() {
            await axios.get(apiURL + 'Center/getCenterList?idParentCenter='+ this.idSelectedCenter, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.centerList = response.data.centerList
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        }
    },
    mounted() {
            var storedCenters = JSON.parse(localStorage.getItem("CertIdCent"));
            this.MyCenters = storedCenters
            this.idSelectedCenter = this.MyCenters[0].idCenter
            this.getCenters()
    }

}
</script>                                        